import React, { useState } from 'react';

const Badge = ({ colorBgClass, colorTxtClass, colorBdrClass, Icon, text }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div 
            className={`relative inline-block overflow-hidden transition-all duration-300 ease-in-out`}
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
        >
                <div
                    className={`
                        rounded-lg flex items-center justify-center p-1
                        border-2 ${colorBdrClass}
                        ${colorBgClass} bg-opacity-20
                    `}
                >
                    <Icon className={` w-6 h-6 ${colorTxtClass} `} />
                    {isHovered && (
                        <span className={`text-sm font-semibold mx-2 ${colorTxtClass} whitespace-nowrap`} >
                            {text}
                        </span>
                    )}
                </div>
        </div>
    );
};

export default Badge;