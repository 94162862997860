import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// SERVICES
import { checkAPI, getUserData } from '../services/AuthService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        // Fonction pour vérifier l'API
        const checkApi = async () => {
            const response = await checkAPI();
            if (!response) {
                navigate('/error');
            }
            console.log('API vérifiée');
        };

        checkApi();

        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await getUserData();
                    setUser(response);
                } catch (error) {
                    console.error('Erreur lors de la récupération des données utilisateur :', error);
                    logout();
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }

        fetchUserData();
    }, []);

    // Fonction pour gérer la déconnexion
    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
        navigate('/auth/twitch');
    };

    return (
        <AuthContext.Provider value={{ user, setUser, loading, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;