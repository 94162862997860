import React from 'react';

// COMPONENTS
import Header from './headerComponent';

function Layout({ children }) {
  return (
    <div>
      <Header />
      <main>{children}</main>
    </div>
  );
}

export default Layout;