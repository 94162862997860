import React, { useState, useEffect, useRef } from 'react';

// COMPONENTS
import Badge from './badgeComponent';

// ICONS
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { UserIcon, CubeIcon, StarIcon, MapPinIcon } from '@heroicons/react/24/outline';

const Card = ({ type, rarity, title, description, image, number }) => {
    const [maxCard, setMaxCard] = useState(30);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const modalImageRef = useRef(null);
    const menuRef = useRef(null);
    const mousePosition = useRef({ x: 0, y: 0 });
    const requestRef = useRef(null);

    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const toggleMenu = () => setIsMenuOpen((prev) => !prev);

    useEffect(() => {
        if (isModalOpen) {
            const handleMouseMove = (e) => {
                const { clientX, clientY } = e;
                const { innerWidth, innerHeight } = window;

                mousePosition.current.x = (clientX - innerWidth / 2) / (innerWidth / 2);
                mousePosition.current.y = (clientY - innerHeight / 2) / (innerHeight / 2);

                if (!requestRef.current) {
                    requestRef.current = requestAnimationFrame(updateTransform);
                }
            };

            const updateTransform = () => {
                if (modalImageRef.current) {
                    modalImageRef.current.style.transform = `rotateX(${mousePosition.current.y * 20}deg) rotateY(${-mousePosition.current.x * 20}deg)`;
                    modalImageRef.current.style.transition = 'transform 0s';
                }
                requestRef.current = null;
            };

            window.addEventListener('mousemove', handleMouseMove);

            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
                if (requestRef.current) {
                    cancelAnimationFrame(requestRef.current);
                }
            };
        } else {
            // Réinitialiser la transformation lorsque la modale est fermée
            if (modalImageRef.current) {
                modalImageRef.current.style.transform = 'rotateX(0deg) rotateY(0deg)';
                modalImageRef.current.style.transition = 'transform 0.3s';
            }
        }
    }, [isModalOpen]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };

        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    const { colorBgClass, colorTxtClass, colorBdrClass, Icon, label } = (() => {
        switch (type) {
            case 'character':
                return { colorBgClass: 'bg-card-type-character', colorTxtClass : 'text-card-type-character', colorBdrClass : 'border-card-type-character', Icon: UserIcon, label: 'PERSONNAGE' };
            case 'object':
                return { colorBgClass: 'bg-card-type-object', colorTxtClass : 'text-card-type-object', colorBdrClass : 'border-card-type-object', Icon: CubeIcon, label: 'OBJET' };
            case 'place':
                return { colorBgClass: 'bg-card-type-place', colorTxtClass : 'text-card-type-place', colorBdrClass : 'border-card-type-place', Icon: MapPinIcon, label: 'LIEU' };
            case 'event':
                return { colorBgClass: 'bg-card-type-event', colorTxtClass : 'text-card-type-event', colorBdrClass : 'border-card-type-event', Icon: StarIcon, label: 'ÉVÉNEMENT' };
            default:
                return { colorBgClass: 'bg-card-type-character', colorTxtClass : 'text-card-type-character', colorBdrClass : 'border-card-type-character', Icon: UserIcon, label: 'PERSONNAGE' };
        }
    })();

    return (
        <>
            {/* Carte principale */}
            <div className="relative group w-full max-w-80 p-8 m-8 bg-white dark:bg-card-background rounded-lg shadow-md">
                {/* Background Effects */}
                <div className={`absolute inset-0 transition-transform duration-500 ease-in-out transform scale-100 rounded-lg 
                    ${
                        type === 'character' ? 'bg-gr-card-background-character-light dark:bg-gr-card-background-character-dark' :
                        type === 'object' ? 'bg-gr-card-background-object-light dark:bg-gr-card-background-object-dark' :
                        type === 'place' ? 'bg-gr-card-background-place-light dark:bg-gr-card-background-place-dark' :
                        type === 'event' ? 'bg-gr-card-background-event-light dark:bg-gr-card-background-event-dark' : 'bg-gr-card-background-character-light dark:bg-gr-card-background-character-dark'
                    } opacity-75 group-hover:rotate-2 group-hover:-translate-x-3 group-hover:translate-y-4 -z-10`}></div>

                {/* Header */}
                <div className="flex flex-col items-start mb-4 h-18">
                    <h2 className="text-2xl font-bold text-black dark:text-white">{title}</h2>
                    <div className="flex items-center mt-2">
                        <Badge colorBgClass={colorBgClass} colorTxtClass={colorTxtClass} colorBdrClass={colorBdrClass} Icon={Icon} text={label} />
                        {/* Rareté */}
                        <div  className={`ml-2 text-sm font-semibold px-3 py-2 rounded-md ${
                                rarity === 'normale'
                                    ? 'bg-card-rarity-normale text-white'
                                    : rarity === 'rare'
                                    ? 'bg-card-rarity-rare text-white'
                                    : rarity === 'ultra-rare'
                                    ? 'bg-card-rarity-ultra-rare text-white'
                                    : rarity === 'mega-rare'
                                    ? 'bg-card-rarity-mega-rare text-white'
                                    : rarity === 'unique'
                                    ? 'bg-card-rarity-unique text-white'
                                    : 'bg-card-rarity-normale text-gray-700'
                            }`}
                        >   
                            {rarity.toUpperCase()}
                        </div>
                    </div>
                </div>
                {/* Body */}
                <div className="mb-4">
                    <img
                        src={image}
                        alt={title}
                        className="w-full h-full object-cover rounded-md transition-transform duration-500 transform group-hover:scale-105 cursor-pointer"
                        onClick={toggleModal}
                    />
                </div>
                {/* Footer */}
                <div className="flex justify-between mt-4 relative">
                    {/* Colonne gauche : Description */}
                    <div className="flex-1">
                        <p className="text-card-description-light dark:text-card-description-dark">
                            {description}
                        </p>
                    </div>

                    {/* Colonne droite : Ellipsis et numéro */}
                    <div className="flex flex-col items-end justify-between">
                        {/* Ellipsis en haut à droite */}
                        <div ref={menuRef}>
                            <EllipsisHorizontalIcon
                                className="w-8 h-8 text-card-ellipsis-light hover:text-card-ellipsis-hover-light dark:text-card-ellipsis-dark dark:hover:text-card-ellipsis-hover-dark cursor-pointer hover:bg-card-btn-ellipsis-hover-light dark:hover:bg-card-btn-ellipsis-hover-dark rounded-full p-1 transition duration-300"
                                onClick={toggleMenu}
                            />
                            {isMenuOpen && (
                                <div className="absolute right-0 mt-2 w-32 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
                                    <ul className="text-sm text-gray-700 dark:text-gray-200">
                                        <li className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                            Partager
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                        {/* Numéro en bas à droite */}
                        <div className="mt-2 text-sm font-semibold text-gray-500 dark:text-gray-300">
                            {number}/{maxCard}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modale */}
            {isModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={toggleModal} // Fermer la modale au clic en dehors
                >
                    <div
                        className="relative"
                        style={{ perspective: '1000px' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <img
                            ref={modalImageRef}
                            src={image}
                            alt={title}
                            className="h-full rounded-md shadow-lg"
                            style={{
                                transform: 'rotateX(0deg) rotateY(0deg)',
                                willChange: 'transform',
                            }}
                        />
                        <div
                            className="mt-4 p-4 rounded-lg bg-blue bg-opacity-90 text-white text-center cursor-pointer hover:bg-blue-dark hover:bg-opacity-90 transition duration-300"
                            onClick={toggleModal}
                        >
                            FERMER
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Card;
