import React, { createContext, useContext, useState, useEffect } from 'react';

// Crée le contexte
const ThemeContext = createContext();

// Hook personnalisé pour accéder au contexte plus facilement
export const useTheme = () => useContext(ThemeContext);

// Composant fournisseur du contexte
export const ThemeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false); // false = mode clair par défaut

    // Fonction pour basculer entre les modes
    const toggleTheme = () => {
        setIsDarkMode((prev) => {
            const newMode = !prev;
            document.documentElement.classList.toggle('dark', newMode);
            return newMode;
        });
    };

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [isDarkMode]);

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};