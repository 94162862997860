import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return (
            <>
                <Helmet>
                    <title>Chargement...</title>
                </Helmet>
                <div>
                    <h1>Chargement...</h1>
                </div>
            </>
        )
    }

    if (!user) {
        return <Navigate to="/auth/twitch" />;
    }

    return children;
};

export default ProtectedRoute;