import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// CONTEXT
import { useTheme } from '../context/ThemeContext';

// ICONS
import { HomeIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/outline';
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid';

const Header = () => {
    const { isDarkMode, toggleTheme } = useTheme();
    const location = useLocation();

    const navItems = [
        { path: '/', label: 'ACCUEIL', icon: <HomeIcon className="w-6 h-6" /> },
        { path: '/profile', label: 'PROFIL', icon: <UserIcon className="w-6 h-6" /> },
    ];

    return (
        <header className={`${isDarkMode ? 'bg-black text-white' : 'bg-white text-black'} border-b-2 p-4`}>
            <nav className="flex justify-between items-center">
                <ul className="flex space-x-6">
                    {navItems.map((item) => (
                        <li 
                            key={item.path}
                            className={`relative group ${
                                location.pathname === item.path ? 'text-blue' : ''
                            }`}
                        >
                            <Link to={item.path} className="flex flex-col items-center md:flex-row md:items-baseline text-center">
                                {/* Icône visible uniquement sur mobile */}
                                <span className="md:hidden">{item.icon}</span>

                                {/* Texte visible uniquement sur desktop */}
                                <span className="hidden md:block text-lg uppercase">
                                    {item.label}
                                </span>
                            </Link>

                            {/* Trait épais sous l'élément */}
                            <span
                                className={`absolute -bottom-1.5 left-0 right-0 h-1 bg-blue rounded-full transition-all duration-300 scale-x-0 group-hover:scale-x-100 ${
                                location.pathname === item.path ? 'scale-x-100' : ''
                                }`}
                            ></span>
                        </li>
                    ))}
                </ul>
                {/* Bouton pour le mode sombre/clair */}
                <button
                    onClick={toggleTheme}
                    className="p-2 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                    {isDarkMode ? (
                        <SunIcon className="w-6 h-6 text-yellow-500" />
                    ) : (
                        <MoonIcon className="w-6 h-6 text-gray-800" />
                    )}
                </button>
            </nav>
        </header>
    );
};

export default Header;