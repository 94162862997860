import axios from 'axios';

// Crée une instance Axios avec des configurations par défaut
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // URL de base pour les appels
    headers: {
        'Content-Type': 'application/json',
    },
});

// Ajouter un token dynamiquement si nécessaire
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Récupérer le token JWT
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Gestion des erreurs dans les réponses
apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        handleApiError(error);
        return Promise.reject(error);
    }
);

const handleApiError = (error) => {
    console.error('API Error:', error);
    if (error.response) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
    } else {
        console.error('Error Message:', error.message);
    }
};

export default apiClient;