import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { sendOAuthCode } from '../api/authApi';

// CONTEXT
import AuthContext from '../context/AuthContext';

const LoginCallback = () => {
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');

        const isCodeUsed = sessionStorage.getItem('oauth_code_used');

        if (code && !isCodeUsed) {
            sessionStorage.setItem('oauth_code_used', 'true');

            // Envoi du code au backend pour obtenir un JWT
            sendOAuthCode(code)
                .then((response) => {
                    const { token, user } = response.data;

                    // Stocker le token dans localStorage
                    localStorage.setItem('token', token);

                    // Mettre à jour le contexte utilisateur
                    setUser(user);

                    // Supprimer le code de la session
                    sessionStorage.removeItem('oauth_code_used');

                    // Rediriger vers la page d'accueil
                    navigate('/');
                })
                .catch((error) => {
                    console.error('Erreur lors de la connexion Twitch :', error);
                    alert('Erreur lors de la connexion. Veuillez réessayer.');
                });
        }
    }, [navigate, setUser]);

    return (
        <>
            <Helmet>
                <title>Connexion en cours...</title>
            </Helmet>
            <div>
                <h1>Connexion en cours...</h1>
            </div>
        </>
    );
};

export default LoginCallback;