import React from 'react';
import { Helmet } from 'react-helmet-async';

const BroadcasterCallback = () => {
    return (
        <>
            <Helmet>
                <title>Connexion en cours...</title>
            </Helmet>
            <div>
                <h1>Connexion en cours...</h1>
            </div>
        </>
    );
};

export default BroadcasterCallback;