import React from 'react';
import { Helmet } from 'react-helmet-async';

const Login = () => {

    const handleLogin = () => {
        const clientId = 'gk7bgn6yzmja9s01yhgo3eatsqa0p0';
        const redirectUri = process.env.REACT_APP_REDIRECT_URI_TWITCH;
        const scopes = ['user:read:email', 'user:read:follows'];
        const twitchAuthUrl = `https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes.join('%20')}`;
      
        window.location.href = twitchAuthUrl;
    };

    return (
        <>
            <Helmet>
                <title>Stream Card | Connexion</title>
                <meta name="description" content="Connectez-vous pour accéder à votre collection." />
            </Helmet>
            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white">
                <div className="w-full max-w-md p-8 bg-gray-800 rounded-lg shadow-md">
                    <h1 className="text-3xl font-bold text-center mb-6">Bienvenue !</h1>
                    <p className="text-center text-gray-400 mb-8">
                        Connectez-vous pour accéder à votre collection.
                    </p>
                    <button
                        onClick={handleLogin}
                        className="w-full py-3 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-lg shadow-md transition duration-300"
                    >
                        Se connecter avec Twitch
                    </button>
                </div>
            </div>
        </>
    );
}

export default Login;