import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// CONTEXT
import AuthContext from '../context/AuthContext';

// COMPONENTS
import Card from '../components/cardComponent';

const Home = () => {
    const { user } = useContext(AuthContext);

    return (
        <>
            <Helmet>
                <title>Stream Card | Accueil</title>
                <meta name="description" content="Bienvenue sur Stream Card !" />
            </Helmet>
            <div className="flex flex-col items-center justify-center">
                <h1 className="text-4xl font-bold text-center text-black dark:text-white mt-8">Bienvenue sur Stream Card, {user?.USR_DISPLAY_NAME} !</h1>
                <div className="grid grid-cols-1 mt-8 sm:grid-cols-2 lg:grid-cols-4">
                    <Card 
                        type="character"
                        rarity="normale"
                        title="DobbyElfe" 
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        image="https://via.placeholder.com/400x600"
                        number="1"
                    />
                    <Card 
                        type="object"
                        rarity="rare"
                        title="Table de PingPong" 
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        image="https://via.placeholder.com/400x600"
                        number="2"
                    />
                    <Card 
                        type="place"
                        rarity="ultra-rare"
                        title="Le Local" 
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        image="https://via.placeholder.com/400x600"
                        number="3"
                    />
                    <Card 
                        type="event"
                        rarity="mega-rare"
                        title="PGW 2024" 
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        image="https://via.placeholder.com/400x600"
                        number="4"
                    />
                    <Card 
                        type="character"
                        rarity="unique"
                        title="Valouzz"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        image="https://via.placeholder.com/400x600"
                        number="5"
                    />
                </div>
            </div>
        </>
    );
};

export default Home;