import apiClient from './api';

export const checkAPI = () => apiClient.get('/');

// Fonction pour envoyer un code OAuth au backend
export const sendOAuthCode = (code) => apiClient.post('/auth/twitch/callback', { code });

// Fonction pour récupérer des données utilisateur
export const getUserData = () => apiClient.get('/auth/user');

// Exemple de déconnexion
export const logout = () => apiClient.post('/auth/logout');