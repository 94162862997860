import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// CONTEXT
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';

// PAGES
import Home from './pages/home';
import Login from './pages/login';
import LoginCallback from './pages/loginCallback';
import BroadcasterCallback from './pages/broadcasterCallback';

// COMPONENTS
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/layoutComponent';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <ThemeProvider>
            <Routes>
              <Route
                path="*"
                element={
                  <Layout>
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  </Layout>
                }
              />
              <Route path="/auth/twitch" element={<Login />} />
              <Route path="/auth/twitch/callback" element={<LoginCallback />} />
              <Route path="/auth/twitch/broadcaster/callback" element={<BroadcasterCallback />} />
            </Routes>
          </ThemeProvider>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;